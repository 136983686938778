import React from "react"
import { FaEnvelope } from "@react-icons/all-files/fa/FaEnvelope";
import { FaPhone } from "@react-icons/all-files/fa/FaPhone";
import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin";
import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactPage = () => (
  <Layout>
    <SEO title="Contact" />
    <div className="container" style={{marginTop:'80px', justifyContent: 'center', color: '#FDF8E8'}}>
      <div className="row" style={{justifyContent: 'center'}}>
        <div className="col-md-6" style={{textAlign: 'center', border: '2px solid #FDF8E8', padding: '35px 0px 22px 0px', backgroundColor: 'rgba(0, 0, 0, 0.15)'}}>
          <h3 style={{color: '#FDF8E8', marginBottom: '10px', fontSize: '19.5px'}}><FaEnvelope/> <a style={{textDecoration: 'none', color: 'white'}} href="mailto:cassandrasessa@gmail.com">cassandra.sessa@gmail.com</a></h3>
          <h3 style={{color: '#FDF8E8', marginBottom: '10px', fontSize: '19.5px'}}><FaPhone/> (647) 389 - 2511</h3>
          <h3 style={{color: '#FDF8E8', marginBottom: '10px', fontSize: '19.5px'}}><FaLinkedin/> <a style={{textDecoration: 'none', color: 'white'}} href="https://www.linkedin.com/in/cassandra-sessa/">/cassandra-sessa</a></h3>
          <i class="far fa-envelope"></i>
        </div>
      </div>
    </div>
  </Layout>
)

export default ContactPage
